import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const cupon = "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/cupones%2FRamirez%20Home%20Services%20cupon.png?alt=media&token=17849bbb-1868-4e7a-a2ea-7e108eef9ad4"

const Directories = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div>
      <div className="container">
         <div className="row justify-content-center text-center pd-top-90 ">
            <img src={cupon} class="imgGb" style={{width:'35%'}}/>
        </div> 
        {/* <div className="row justify-content-center text-center pd-top-90">
        <a href={rpdata?.gmb?.link} target="blank">
            <img src={rpdata?.gmb?.img} class="imgGb" style={{width:'30%'}}/>
          </a>
        </div> */}
        <h2 className="pd-top-90 text-center">Find Us</h2>
        <div className="row justify-content-center pd-top-20">
          
          {rpdata?.dbSocialMedia?.directorios.map((item, index) => {
            return (
              <div
                className="col-lg-2 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })}
          {rpdata?.dbSocialMedia?.redes.map((item, index) => {
            return (
              <div
                className="col-lg-3 col-md-6 text-center content-directorios"
                key={index}
              >
                <a href={item.url} target="_blank">
                  <img src={item.image}></img>
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Directories;
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import Data from "../../api/data.json";

const ServicePageArea = ({
  service_image_num,
  ser_icon_img,
  ser_title,
  colorClass,
  ser_description,
  ser_itemservices,
  event_click,
}) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="col-lg-4 col-md-6">
        <div className="single-team-inner">
          <div className="thumb">
            <img
              src={service_image_num}
              alt="img"
              className="img-card-services"
            />
            <div className="social-wrap">
              <div className="social-wrap-inner">
                <a className="social-share" href="#">
                  <i className="fa fa-share-alt text-white" />
                </a>
                <ul>
                  {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={item.url} target="_blank">
                          <i className={`fab fa-${item.icon} text-white`} />
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="details">
            <h4>
              <Link to="/services" onClick={event_click}>
                {ser_title}
              </Link>
            </h4>
            <span>{ser_description.substring(0, 120) + ".."}</span>
            <div>
              <Link
                to="/services"
                onClick={event_click}
                className="btn btn-base b-animate-3 mr-sm-3 mr-2 mt-2"
              >
                MORE INFO
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePageArea;

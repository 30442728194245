import React, {useContext} from 'react'
import { GlobalDataContext } from '../../context/context';

const AboutPage = () => {
    const { rpdata } = useContext(GlobalDataContext);
    return <div>
        <div className="contact-g-map">
        <h2 className="text-center">We Cover {rpdata?.dbPrincipal?.miles} Miles around {rpdata?.dbPrincipal?.location[0].address}</h2>
        <iframe src={rpdata?.dbPrincipal?.location[0].url} />
      </div>
    </div>
}

export default AboutPage

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FaHandshake } from "react-icons/fa";
import { BsAward} from "react-icons/bs";
import { GiTargetArrows } from "react-icons/gi";
import { GlobalDataContext } from "../../context/context";

const Values = (props) => {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div className="events-area pd-top-110 pd-bottom-10 go-top">
      {/* <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7 col-md-11">
            <div className="section-title text-center">
              <h6 className="sub-title double-line">
                {rpdata?.dbPrincipal?.name}
              </h6>
              <h2 className="title">{rpdata?.dbSlogan?.[2].slogan}</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <ul
              className="single-blog-list-wrap style-white"
              style={{ backgroundColor: "black" }}
            >
              <li>
                <div className="media single-blog-list-inner style-white">
                  <div className="date">
                    <BsAward className="icon-values text-white" />
                  </div>
                  <div className="media-body details ">
                    <h5>
                      <Link to="/contact">Mission</Link>
                    </h5>
                    <p className="text-white">
                      {" "}
                      {rpdata?.dbValues?.[0].description}{" "}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="media single-blog-list-inner style-white">
                  <div className="date">
                    <GiTargetArrows className="icon-values text-white" />
                  </div>
                  <div className="media-body details">
                    <h5>
                      <Link to="/contact">Vision</Link>
                    </h5>
                    <p className="text-white">
                      {" "}
                      {rpdata?.dbValues?.[1].description}{" "}
                    </p>
                  </div>
                </div>
              </li>
              <li>
                <div className="media single-blog-list-inner style-white">
                  <div className="date">
                    <FaHandshake className="icon-values text-white" />
                  </div>
                  <div className="media-body details">
                    <h5>
                      <Link to="/contact">Why Choose Us</Link>
                    </h5>
                    <p className="text-white">
                      {" "}
                      {rpdata?.dbValues?.[2].description}{" "}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="event-thumb">
              <img src={rpdata?.dbPrincipal?.logo}
                alt="img" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="valores">
      <div className="section-title text-center">
              <h6 className="sub-title double-line">
                {rpdata?.dbPrincipal?.name}
              </h6>
              <h2 className="title">{rpdata?.dbSlogan?.[2].slogan}</h2>
      </div>
      <div className="row justify-content-center">
					  <div className="col-md-4">
			            <div className="valor-inner">
                    <div>
                      <div>
			                  <BsAward className="iconV"/>
			                </div>
                    </div>
			              <div className="media">
			                <div className="media-body align-self-center">
			                  <h5 className="">Mission</h5>
			                </div>
			              </div>
                    <div>
                    <p >
                      {" "}
                      {rpdata?.dbValues?.[0].description}{" "}
                    </p>
                    </div>
			            </div>
			          </div>
			          <div className="col-md-4">
                  <div className="valor-inner">
                      <div>
                        <div>
                          <GiTargetArrows className="iconV"/>
                        </div>
                      </div>
                      <div className="media">
                        <div className="media-body align-self-center">
                          <h5 className="">Vision</h5>
                        </div>
                      </div>
                      <div>
                      <p >
                      {" "}
                      {rpdata?.dbValues?.[1].description}{" "}
                    </p>
                      </div>
                    </div>
			          </div>
			          <div className="col-md-4">
                <div className="valor-inner">
                    <div>
                      <div>
			                  <FaHandshake className="iconV"/>
			                </div>
                    </div>
			              <div className="media">
			                <div className="media-body align-self-center">
			                  <h5 className="">Why Choose</h5>
			                </div>
			              </div>
                    <div>
                    <p >
                      {" "}
                      {rpdata?.dbValues?.[2].description}{" "}
                    </p>
                    </div>
			            </div>
			          </div>
                
			        </div>
      </div>
    </div>
  );
};

export default Values;
